.container {
    max-width: var(--MAXWIDTH);
    width: 50%;
    margin: 10px auto 10px;
    padding-bottom: 10px;
    position: relative;
    color: var(--text-color);
}

.headerSection {
    display: inline-block;
position: relative;

}



.mainHeader {
    padding: 0;
    margin: 0;
    font-size: clamp(12px, 1.2vw, 20px);
}

.subHeader {
    padding: 0;
    margin: 2px;
    font-weight: 400;
    font-size: clamp(12px, 1.2vw, 20px);
}


.description {
    text-align: left;
    color: var(--text-color);
    font-size: clamp(12px, 1.2vw, 16px);
    line-height: 22px;
}


@media screen and (max-width: 768px) {
.container {
    width: 90%;
}
}

@media screen and (max-width: 576px){
.headerSection {
    width: 80%;
}
}
