.container {
    max-width: var(--MAXWIDTH);
    margin: 10px auto 10px;
    position: relative;
    color: var(--text-color);
    padding: 0 10px 10px 10px  ;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

.buttonWrap {
    width: 250px;
    background-color: #6c6e70;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
}


.button {
    border: none;
    background-color: transparent;
    border-radius: 25px;
    cursor: pointer;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
    text-decoration: none;
}
.button:hover, .button:focus, .button:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border: none;
    outline: none;
}




.iconImg {
    width: 40px;
    height: 40px;
}
.iconText {
    color: white;
    font-weight: 600;
    margin: auto;
    font-size: 17px;
    line-height: 25px;
    padding: 0;
    border-radius: 25px;

}

.iconContainer {
    width: 65px;
    height: 65px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin: 0;
    border-right: 2px solid white;
}

.calendarButton {
    border: none;

}

.mailIcon {
    background: linear-gradient(180deg, #e1ab4a, #d5804d );


}

.whatsappIcon {
    background: linear-gradient(180deg, #8acf6a, #6bb344);
}

.timeIcon {
    background: linear-gradient(180deg, #51c0e7, #3f76a0);
}

@media screen and (max-width: 768px) {
 .container {
     flex-direction: column;
 }

    .button {
        margin-bottom: 10px;
    }
}
