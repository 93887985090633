.container {
    width: 100%;
    background-color: #50b3e4;
    //height: 70px;
    padding: 0 5px;
}

.mainContent {
    max-width: var(--INNERWIDTH);
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    height: 100%;
}

.section {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-evenly;
    padding: 5px 0;

}
.icon {
    height: 10%;
    width: 10%;
}

.link {
    text-decoration: none;
    color: #fff;
    margin: auto 0;
}
.section:not(:last-child) {
    border-right: 1px solid #fff;

}

@media screen and (max-width: 768px) {
    .container {
        height: auto;
    }
    .mainContent{
        flex-direction: column;
    }

    .section {
        width: 50%;
    }
    .section:not(:last-child) {
        border: none;
        border-bottom: 1px solid #fff;


    }
}

@media screen and (max-width: 576px) {
    .icon {
        width: 5%;
        height: 5%;
    }
    .section {
        width: 100%;
    }
    .section:last-child {
        margin-bottom: 10px;
    }
    .link {
        width: 50%;
        font-size: 12px;
    }
}


