.App {
  text-align: center;
  margin: auto;
  padding: 25px 0 0 0;
  /*overflow: hidden;*/
  background-color: var(--main-background-color);
  background-size: 100% 25vmin;
  background-repeat: no-repeat;
  background-image: url("assets/images/header_back.webp"), url("assets/images/footer_back.webp");
  min-height: 100vh;
  background-position-y:  20vh, 80%;
  position: relative;

}

.AppHeader {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;-*/

}

.mainSection {
min-height: 100vh;
  margin: 0 auto;
}


.chatBlock {
  height: 0;
  transition: height 0.2s linear;
  border: 1px solid var(--chat-button-color);
  margin: auto;
}



.active {
  height: 600px;
}

.fixed {
position: fixed;
  bottom: 0;
  right: 10%;
  transform: translateY(0);
}

.absolute {
  position: absolute;
  transform: translateY(-100%);
  right: 10%;
}

.chatContainer {
  width: 300px;
  /*margin: auto;*/
  z-index: 1;
}
.chatWrap {
  position: relative;
}
.chatBtn {

  background-color: var(--chat-button-color);
  color: #fff;
  font-size: 22px;
  border: none;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding: 5px 0;
  min-width: 300px;
  cursor: pointer;
  font-family: Neo-latina, sans-serif;
}

@media screen and (max-width: 1800px) {
  .App {
    background-position-y:  35vh, 80%;
  }
}

@media screen and (max-width: 1600px) {
  .App {
    background-position-y:  25vh, 80%;
  }
}

@media screen and (max-width: 1500px) {
  .App {
    background-position-y:  25vh, 80%;
  }
}


@media screen and (max-width: 1400px) {
  .App {
    background-position-y:  28vh, 80%;
  }
}

@media screen and (max-width: 1300px) {
  .App {
    background-position-y:  28vh, 80%;
  }
}

@media screen and (max-width: 1200px) {
  .App {
    background-position-y:  25vh, 80%;
  }
}

@media screen and (max-width: 1100px) {
  .App {
    background-position-y:  22vh, 80%;
  }
}

@media screen and (max-width: 860px) {
  .App {
    background-position-y:  22vh, 80%;
  }
}

@media screen and (max-width: 820px) {
  .App {
    background-position-y:  20vh, 80%;
  }
}

@media screen and (max-width: 768px) {
 .App {
   background-position-y:  40vh, 80%;
 }
}


@media screen and (max-width: 576px) {
  .App {
    background-position-y:  10vh, 80%;
  }
  .absolute {
    position: absolute;
    transform: translate(-50%, -100%);
    left: 50%
  }

  .fixed {
    position: fixed;
    bottom: 0;
    transform: translateX(-50% );
  left: 50%;

  }
}
