@import '~@csstools/normalize.css';
*, *::after, *::before {
  box-sizing: border-box;
}
@font-face {
  font-family: "Neo-latina";
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url("../public/fonts/neo_latina.woff2") format("woff2"),
  url("../public/fonts/neo_latina.woff") format("woff");
  font-display: swap;
}


:root {

  --text-color: #6c6e70;
  --download-link-color: #3f494d;
  --download-link-color-hover: #8ca5b2;
  --header-text-color: #6c6d6f;
  --header-border-color: #c6caca;
  --header-background-color-start: #f2f2f2;
  --header-background-color-finish: #dadddd;
  --main-background-color: #fdfdfd;
  --bubbles-color: #212629;
  --text-bubbles: #6e609e;
  --text-footer: #cfcfce;
  --send-button-hover: #ef6324;
  --chat-button-color: #ef6324;
  --button-hero-shadow: #fbf7b1;
  --converse-text: #49abd7;
  --block-background: #f2f1f0;
  --packages-card-border: #d8d8d9;
  --MAXWIDTH: 1440px;
  --INNERWIDTH: 1320px;

  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;

}
.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: transparent !important;
  border: 2px solid #6c6e70;
}

.swiper-pagination-bullet-active {
  background-color: #6c6e70 !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  left: 25% !important;
}

html {
  background-color: var(--main-background-color);
  position: relative;
}

body {
  margin: 0;
  font-family: "Century Gothic", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color);
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.placeholder {
  transform: translateY(25px) !important;
}

@media screen and (max-width: 768px) {
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 0 !important;
  }
}
