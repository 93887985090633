.container {
    max-width: var(--MAXWIDTH);
    margin: 10px auto 10px;
    padding-bottom: 10px;
    position: relative;
    color: var(--text-color);
}

.mainHeader {
    padding: 0;
    margin: 0 0 20px 0;
    font-size: clamp(12px, 1.2vw, 20px);
}

.mainSection {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

.imageSection {
    width: 50%;
    align-self: center;
    text-align: right;
}
.imgFeatures {
    width: 70%;
}
.descriptionSection {
    width: 50%;
}

.list {
    list-style-type: none;
    padding: 0;
}

.listItem {
    display: flex;
    flex-direction: row;
}

.listItemIconSection {
    width: 20%;
    margin: auto;
}

.listItemIcon{
   width: 50%;
}



.listItemDescription {
    width: 80%;
    text-align: left;
}

@media screen and (max-width: 1024px) {
.mainSection {
    flex-direction: column;
}

    .imageSection {
        width: 80%;
        margin: auto;
        align-self: center;
        text-align: center;

    }
    .imgFeatures {
        width: 50%;
        margin: auto;
    }
    .descriptionSection {
        width: 100%;
    }
}

@media screen and (max-width: 576px){
    .imageSection {
        width: 100%;
        margin: auto;
        align-self: center;
        text-align: center;

    }
    .imgFeatures {
        width: 100%;
        margin: auto;
    }
    .descriptionSection {
        width: 100%;
    }
    .listItem {
        flex-direction: column;
    }
    .listItemDescription {
        width: 100%;
        text-align: justify;
    }
}
