.container {
    max-width: var(--MAXWIDTH);
    margin: 10px auto 10px;
    position: relative;
    color: var(--text-color);
    padding: 0 10px 10px 10px  ;
}

.header__content{
    display: flex;
    flex-direction: row;
}

.swiper {
    cursor: pointer;
    width: 70%;
}

.logo{
width: 30%;
}

.logo_img {
width: 50%;
}

.swiper__slide {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.swiper__slide_imgContainer {
    width: 45%;
}

.swiper__slide_img {
    width: 100%;
}
.swiper__slide_description {
    font-size: clamp(10px, 1.2vw, 16px);
    width: 45%;
}
.swiper__slide_header {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: clamp(12px, 1.2vw, 20px);
}

.swiper__slide_text {
    text-align: left;
    line-height: 18px;

}
@media screen and (max-width: 1200px){
    .logo {
        width: 20%;
    }
    .swiper {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .header__content {
        flex-direction: column-reverse;
    }
    .container {
        margin-bottom: 80px;
    }

    .swiper__slide_description {
        padding-bottom: 25px;
    }


    .swiper  {
        width: 90%;
        margin: auto;
    }
    .logo {
        width: 30%;
        margin: 0 auto 10px;
    }
}

@media screen and (max-width: 740px) {
    .header__content {
        flex-direction: column-reverse;
    }
    .container {
        margin-bottom: 80px;
    }


}

@media screen and (max-width: 576px){
    .container {
        margin-bottom: 0px;
    }
    .swiper  {
        width: 100%;
        margin: auto;
    }
    .swiper__slide {
        flex-direction: column;
    }
    .swiper__slide_description {
        width: 100%;
        padding-bottom: 25px;
    }

    .swiper__slide_text {
        margin: 0;
    }

    .swiper__slide_imgContainer {
        margin: auto;
    }

}
