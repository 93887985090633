.converseContainer {
    font-family: "Neo-latina", sans-serif;
    font-size: 1.4em;
    font-weight: 400;
    line-height: 0;
    white-space: nowrap;
}
.converseText {
    color: var(--converse-text);
}


